import React from "react";
import { Link } from "react-router-dom";

export default function Footer() {
    return (
        <footer>
            <div class="container">

                <div class="row">

                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div class="f-head">Solutions</div>
                        <div class="f-list">
                            <ul >
                                <li>Commerce Support</li>
                                <li>Banking Integration</li>
                                <li>Customer Relationship Management</li>
                                <li>Employee Management</li>
                            </ul>

                        </div>

                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div class="f-head">Support for Business</div>
                        <div class="f-list">
                            <ul >
                                <li>Food & Beverage</li>
                                <li> Retail</li>
                                <li>Beauty</li>
                               
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div class="f-head">About</div>
                        <div class="f-list">
                            <ul>
                        
                                <li><Link to="/" data-scroll-nav="1"> Features </Link></li>
                                <li><Link to="pricing">Pricing </Link></li>
                                <li><Link to="return-policy">Shipping & Return Policy  </Link></li>
                                  <li><a href="http://app.loyaltydish.com">Login</a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="col-lg-3 col-md-3 col-sm-6 col-xs-12">
                        <div class="f-head">Contact</div>
                        <div class="f-list">
                            <ul>
                                <li>Customer support(USA)<span>+1 (512) 730-0349</span></li>
                                <li>Email<span>support@loyaltydish.com</span></li>
                              
                            <li>Follow Us<span><a href=""><i class="fab fa-facebook-f"></i></a><a href=""><i class="fab fa-instagram"></i></a><a href=""><i class="fa fa-envelope"></i></a></span></li>

                        </ul>

                    </div>
                </div>
            </div>

            <div class="footer-logo">
                <a href="/"><img src="images/logo.png" />
                <span>Loyalty<b>Dish</b></span>
                </a>
                <div class="rights">© 2023 LoyaltyDish. All rights reserved.</div>
                <div class="terms"><Link to="/privacy">Privacy Policy</Link></div>
                <div class="terms"><Link to="/terms">Terms & Conditions</Link></div>
                </div>
        </div>
       </footer >

    )
}
