import { useEffect } from 'react';
import './App.css';
import Footer from './components/footer';
import Header from './components/header';
import Home from './pages/home';
import { Route, Routes, useLocation } from "react-router-dom";
import $ from "jquery";
import Contact from './pages/contact';
import Pricing from './pages/pricing';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import ReturnPolicy from './pages/return-policy';
function App() {
  const { pathname } = useLocation();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  useEffect(() => {
    window.$(document).ready(function () {
      window.$('.intro-slides').owlCarousel({
        loop: false,
        margin: 100,
        autoplay: false,
        mouseDrag: false,
        responsiveClass: true,
        nav: false,
        animateOut: 'slideOutUp',
        animateIn: 'slideInUp',
        responsive: {
          0: {
            items: 1,
            nav: false,
            dots: false
          },

        }
      })

      window.$('.solutions').owlCarousel({
        loop: false,
        margin: 70,
        autoplay: false,
        mouseDrag: false,
        responsiveClass: true,
        nav: false,

        responsive: {
          0: {
            items: 1,
            nav: false,
            dots: true
          },
          600: {
            items: 1,
            nav: false
          },
          1000: {
            items: 2,
            nav: false
          },
          1600: {
            items: 2,
            dots: true

          },

        }
      })
      window.$('.features').owlCarousel({
        loop: false,
        margin: 70,
        autoplay: false,
        mouseDrag: true,
        responsiveClass: true,
        nav: false,
        dots: true,

        responsive: {
          0: {
            items: 1,
            nav: false,
            dots: true
          },
          600: {
            items: 2,
            nav: false
          },
          1400: {
            items: 4,
            nav: false
          },
          1600: {
            items: 4,


          },

        }
      })

    })

    window.$('#bar').scrollFix();
    $(window).on("scroll", function () {
      if ($(window).scrollTop() > 100) {
        window.$("header").addClass("myheader");
      } else {
        window.$("header").removeClass("myheader");
      }
    });


    window.$.scrollIt({
      upKey: 38,             // key code to navigate to the next section
      downKey: 40,           // key code to navigate to the previous section
      easing: 'linear',      // the easing function for animation
      scrollTime: 600,       // how long (in ms) the animation takes
      activeClass: 'active', // class given to the active nav element
      onPageChange: null,    // function(pageIndex) that is called when page is changed
      topOffset: -80           // offste (in px) for fixed top navigation
    });

  }, [])

  return (
    <>
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/pricing" element={<Pricing />} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/return-policy" element={<ReturnPolicy />} />
      </Routes>
      <Footer />
    </>
  );
}

export default App;
