import React from "react";

export default function Privacy() {
    return (
        <div className="privacy-bg">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12 col-md-6 col-sm-12">
                        <h3>Privacy Policy</h3>
                        <div className="sep-lf" />
                    </div>
                    <div className="col-lg-12 ">
                        <p>
                            LoyaltyDish is committed to protecting your privacy. This Privacy Policy
                            sets out how we collect, store, process, transfer, share and use data
                            that identifies or is associated with you ("personal information") and
                            information regarding our use of cookies and similar technologies.
                        </p>
                        <p>
                            LoyaltyDish provides web-based software that allow customers to manage
                            customer data and automate marketing activities to send relevant
                            communications to subscribers via our websites (our "Websites")
                            located at www.LoyaltyDish.com (the "Services"). This Privacy Policy
                            applies to our Websites and the Services. ‍
                        </p>
                        <p>
                            Before accessing our Websites or using the Services, please ensure
                            that you have read and understood our collection, storage, use and
                            disclosure of your personal information as described in this Privacy
                            Policy.
                        </p>
                        <p>
                            LoyaltyDish is the data controller of the personal information we hold
                            about you.
                        </p>
                        <hr />
                        <h5>PERSONAL INFORMATION WE COLLECT ABOUT YOU AND HOW WE USE IT</h5>
                        <div className="para-set">
                            <p>
                                We process personal information about individuals that are our
                                direct customers and, where our customer is a business or similar
                                entity, individuals that are our customer’s employees, agents and
                                other representatives. These individuals about whom we collect
                                personal information are referred to as "you" throughout this
                                Privacy Policy.
                            </p>
                            <p>
                                We collect personal information about you when you voluntarily
                                submit information directly to us when you access our Websites or
                                use the Services. This can include information you provide to us
                                when you register for an account, fill in a form, correspond with us
                                via the Websites, phone, email or otherwise, subscribe to our
                                mailing lists, newsletters or other forms of marketing
                                communications, respond to surveys or use some other feature of the
                                Services as available from time to time.
                            </p>
                            <p>
                                If you choose not to provide personal information, we may not be
                                able to provide the Services to you or respond to your other
                                requests.
                            </p>
                            <p>
                                Annex 1 sets out the categories of personal information we collect
                                about you and how we use that information. The table also lists the
                                legal basis which we rely on to process the personal information,
                                the categories of recipients of the personal information and
                                information as to how we determine applicable retention periods.
                            </p>
                            <p>
                                We also automatically collect personal information about you
                                indirectly about how you access and use the Services and information
                                about the device you use to access the Services.
                            </p>
                            <p>
                                ‍ Annex 2 sets out the categories of personal information we collect
                                about you automatically and how we use that information. The table
                                also lists the legal basis which we rely on to process the personal
                                information, the categories of recipients of the personal
                                information and information as to how we determine applicable
                                retention periods.
                            </p>
                            <p>
                                We may link or combine the personal information we collect about you
                                and the information we collect automatically. This allows us to
                                provide you with a personalized experience regardless of how you
                                interact with us.
                            </p>
                            <p>
                                ‍ We may anonymise and aggregate any of the personal information we
                                collect, including information about individuals that are your
                                subscribers, website visitors or customers (so that it does not
                                directly identify you or them). We may use anonymised information
                                for purposes that include testing our IT systems, research, data
                                analysis, improving the Services and developing new products and
                                features. We may also share such anonymised information with others.
                            </p>
                        </div>
                        <hr />
                        <h5>
                            CUSTOMER DATA WE MAY PROCESS THROUGH THE PROVISION OF OUR SERVICE
                        </h5>
                        <div className="para-set">
                            <p>
                                Please note that, during the provision of our Services, we may also
                                process information about individuals that are subscribers, website
                                visitors or customers of our customers (which we call "Customer
                                Data").
                            </p>
                            <p>
                                The relevant customer will have its own privacy policy which will
                                apply to Customer Data and any questions should be directed to that
                                customer.
                            </p>
                        </div>
                        <hr />
                        <h5>USAGE OF DATA FROM ASSOCIATED APPS</h5>
                        <div className="para-set">
                            <p>
                                LoyaltyDish’s use and transfer of information received from Google APIs
                                to any other app will adhere to Google API Services User Data
                                Policy, including the Limited Use requirements.
                            </p>
                            <p>
                                We are committed to safeguarding the privacy and security of our
                                users' data. Our application adheres to the following guidelines:
                            </p>
                            <div className="sub-para">
                                <p>
                                    <b>Data Access:</b> Our OAuth app will only access the necessary
                                    data required to provide the intended functionality, as described
                                    in our application purpose.
                                </p>
                                <p>
                                    <b>Data Storage:</b> Any data obtained from the Google Ads API
                                    will be securely stored and protected in compliance with
                                    industry-standard security measures.
                                </p>
                                <p>
                                    <b>Data Retention:</b> We will retain user data only for as long
                                    as necessary to fulfil the purpose for which it was collected, or
                                    as required by applicable laws and regulations.
                                </p>
                                <p>
                                    <b>Data Sharing: </b>We do not share any user data obtained from
                                    the Google Ads API with third parties, unless explicitly
                                    authorised by the user or required by law.
                                </p>
                            </div>
                        </div>
                        <hr />
                        <h5>MARKETING AND ADVERTISING</h5>
                        <div className="para-set">
                            <p>
                                From time to time, we may contact you with relevant information
                                about the Services and to help customize your marketing strategies.
                                Most messages we send will be by email. For some messages, we may
                                use personal information we collect about you to help us determine
                                the most relevant information to share with you.
                            </p>
                            <p>
                                If you do not want to receive such messages from us, you may opt out
                                by clicking on the unsubscribe link at the bottom of our emails.
                                Please note that you are not permitted to unsubscribe or opt-out of
                                non-promotional messages regarding your account, such as account
                                verification, changes or updates to features of the Services, or
                                technical or security notices.
                            </p>
                        </div>
                        <hr />
                        <h5>MARKETING AND ADVERTISING</h5>
                        <div className="para-set">
                            <p>
                                <b>Security.</b> We implement appropriate technical and
                                organizational measures to protect your personal information against
                                accidental or unlawful destruction, loss, change or damage. All
                                personal information we collect will be stored on secure servers.
                                All electronic transactions entered into via our Website will be
                                protected by SSL encryption technology. Where you register for an
                                account on the Services, you are responsible for maintaining your
                                account’s security as more specifically explained in our Terms of
                                Service.
                            </p>
                            <p>
                                <b>International Transfers of your Personal Information. </b>The
                                personal information we collect may be transferred to and stored in
                                countries outside of the jurisdiction you are in, where we and our
                                third party service providers have operations. If you are located in
                                the European Union ("EU"), your personal information will be
                                processed outside of the EU including in the United States. If we
                                transfer your personal information from the EU to third parties
                                outside the EU and to countries not subject to schemes which are
                                considered as providing an adequate data protection standard, we
                                will enter into contracts which are based on the EU Standard
                                Contractual Clauses with these parties.
                            </p>
                        </div>
                        <hr />
                        <h5>YOUR RIGHTS IN RESPECT OF YOUR PERSONAL DATA</h5>
                        <p className="italics">
                            {" "}
                            This section applies if you are located in the EU.
                        </p>
                        <div className="para-set">
                            <p>
                                In accordance with applicable privacy law, you have the following
                                rights in respect of your personal information that we hold:
                            </p>
                            <div className="sub-para">
                                <p>
                                    <b>Right of access and portability.</b> The right to obtain access
                                    to and to receive your personal information.
                                </p>
                                <p>
                                    <b>Right to rectification.</b> The right to obtain rectification
                                    of your personal information without undue delay where that
                                    personal information is inaccurate or incomplete.
                                </p>
                                <p>
                                    <b>Right to erasure. </b>The right to obtain the erasure of your
                                    personal information without undue delay in certain circumstances,
                                    such as where the personal information is no longer necessary in
                                    relation to the purposes for which it was collected or processed.
                                </p>
                                <p>
                                    <b>Right to restriction.</b> The right to obtain the restriction
                                    of the processing undertaken by us on your personal information in
                                    certain circumstances, such as where the accuracy of the personal
                                    information is contested by you, for a period enabling us to
                                    verify the accuracy of that personal information.
                                </p>
                                <p>
                                    <b>Right to object.</b> The right to object, on grounds relating
                                    to your particular situation, to the processing of your personal
                                    information, and to object to processing of your personal
                                    information for direct marketing purposes, to the extent it is
                                    related to such direct marketing.
                                </p>
                                <p>
                                    If you wish to exercise one of these rights, please contact us
                                    using the contact details at the end of this Privacy Policy.
                                </p>
                                <p>
                                    You also have the right to lodge a complaint to your local data
                                    protection authority. Further information about how to contact
                                    your local data protection authority is available at{" "}
                                    <a href="https://ec.europa.eu/newsroom/article29/items/612080">
                                        https://ec.europa.eu/newsroom/article29/items/612080.
                                    </a>
                                </p>
                            </div>
                        </div>
                        <hr />
                        <h5>COOKIES AND SIMILAR TECHNOLOGIES</h5>
                        <div className="para-set">
                            <p>
                                When you use our Service, read our emails, or otherwise engage with
                                us through a computer or mobile device, we and our third party
                                service providers, automatically collect information about how you
                                access and use the Service and information about the device you use
                                to access the Service. We use this information to enhance and
                                personalize your user experience, to monitor and improve our
                                websites and services, and for other internal purposes.
                            </p>
                            <p>
                                Annex 2 sets out the categories of personal information we collect
                                about you automatically and how we use that information.
                            </p>
                            <p>
                                We typically collect this information through a variety of tracking
                                technologies, including cookies, web beacons, embedded scripts,
                                location-identifying technologies, and similar technology
                                (collectively, “tracking technologies”). We may combine this
                                information with other personal information we collect directly from
                                you.
                            </p>
                            <p>
                                Our Websites use cookies to distinguish you from other users of our
                                Websites. Cookies are pieces of code that allow for personalization
                                of our Websites by saving your information such as user ID and other
                                preferences. A cookie is a small data file that we transfer to your
                                device's hard disk (such as your computer or smartphone) for
                                record-keeping purposes.
                            </p>
                            <b>We use the following types of cookies:</b>
                            <div className="sub-para">
                                <p>
                                    <b>Strictly necessary cookies.</b> These are cookies that are
                                    required for the essential operation of our Websites such as to
                                    authenticate users and prevent fraudulent use.
                                </p>
                                <p>
                                    <b>Analytical/performance cookies.</b> They allow us to recognise
                                    and count the number of visitors and to see how visitors move
                                    around our Websites when they are using it. This helps us to
                                    improve the way our Websites work, for example, by ensuring that
                                    users are finding what they are looking for easily.
                                </p>
                                <p>
                                    <b>Functionality cookies.</b> These are used to recognise you when
                                    you return to our Websites. This enables us to personalize our
                                    content for you, greet you by name and remember your preferences
                                    (for example, your choice of language or region).
                                </p>
                                <p>
                                    <b>Targeting cookies.</b> These cookies record your visit to our
                                    Websites, the pages you have visited and the links you have
                                    followed. We will use this information to make our Websites and
                                    the advertising displayed on them more relevant to your interests.
                                    We may also share this information with third parties for this
                                    purpose.
                                </p>
                            </div>
                            <p>
                                We may collect analytics data, or use third-party analytics tools
                                such as Google Analytics, to help us measure traffic and usage
                                trends for the Service and to understand more about the demographics
                                of our users. You can learn more about Google’s practices at{" "}
                                <a href="http://www.google.com/policies/privacy/partners">
                                    http://www.google.com/policies/privacy/partners
                                </a>
                                , and view its currently available opt-out options at
                                https://tools.google.com/dlpage/gaoptout.
                            </p>
                            <p>
                                We may also work with third party partners to employ technologies,
                                including the application of statistical modelling tools, which
                                permit us to recognize and contact you across multiple devices.
                            </p>
                            <p>
                                {" "}
                                Although we do our best to honor the privacy preferences of our
                                users, we are unable to respond to Do Not Track signals set by your
                                browser at this time.
                            </p>
                            <p>
                                The cookies we use are designed to help you get the most from our
                                Websites but if you do not wish to receive cookies, most browsers
                                allow you to change your cookie settings. Please note that if you
                                choose to refuse cookies you may not be able to use the full
                                functionality of our Websites. These settings will typically be
                                found in the "options" or "preferences" menu of your browser. In
                                order to understand these settings, the following links may be
                                helpful, otherwise you should use the "Help" option in your browser
                                for more details.
                            </p>
                            <div className="sub-para">
                                <p>Cookie settings in Internet Explorer</p>
                                <p>Cookie settings in Firefox</p>
                                <p>Cookie settings in Chrome</p>
                                <p>Cookie settings in Safari web and iOS</p>
                            </div>
                            <p>
                                Deleting cookies does not delete non-cookie technologies such as
                                Local Storage Objects (LSOs) such as Flash objects and HTML5. You
                                can learn more about Flash objects - including how to manage privacy
                                and storage settings for Flash cookies – on Adobe’s website or by
                                clicking here. Various browsers may offer their own management tools
                                for removing HTML5 LSOs.
                            </p>
                        </div>
                        <hr />
                        <h5>THIRD PARTY DATA COLLECTION AND INTEREST BASED ADVERTISING</h5>
                        <div className="para-set">
                            <p>
                                We participate in interest-based advertising and use third party
                                advertising companies to serve you targeted advertisements based on
                                your browsing history. We permit third party online advertising
                                networks, social media companies and other third party services to
                                collect information about your use of our websites and mobile apps
                                over time so that they may play or display ads on our Service, on
                                other websites, apps or services you may use, and on other devices
                                you may use. Typically, though not always, the information used for
                                interest-based advertising is collected through cookies or similar
                                tracking technologies, which recognize the device you are using and
                                collect information, including click stream information, browser
                                type, time and date you visited the site and other information. We
                                may share a common account identifier (such as an email address or
                                user ID) with our third party advertising partners to help identify
                                you across devices.
                            </p>
                            <p>
                                We and our third party partners use this information to make the
                                advertisements you see online more relevant to your interests, as
                                well as to provide advertising-related services such as reporting,
                                attribution, analytics and market research.
                            </p>
                            <p>
                                To learn about interest-based advertising and how you may be able to
                                opt-out of some of this advertising, you may wish to visit the
                                Network Advertising Initiative’s online resources, at{" "}
                                <a href="https://optout.networkadvertising.org/">
                                    https://optout.networkadvertising.org/
                                </a>
                                , the DAA’s resources at{" "}
                                <a href="www.aboutads.info/choices">www.aboutads.info/choices</a>{" "}
                                and/or Your Online Choices at
                                <a href="www.youronlinechoices.com/uk">
                                    {" "}
                                    www.youronlinechoices.com/uk.
                                </a>{" "}
                                You may also be able to limit interest-based advertising through the
                                settings on your mobile device by selecting “limit ad tracking”
                                (iOS) or “opt-out of interest based ads” (Android). You may also be
                                able to opt-out of some – but not all – interest-based ads served by
                                mobile ad networks by visiting https://youradchoices.com/appchoices
                                and downloading the mobile AppChoices app. Please note that
                                opting-out of receiving interest-based advertising through the NAI’s
                                and DAA’s or Your Online Choices online resources will only opt-out
                                a user from receiving interest-based ads on that specific browser or
                                device, but the user may still receive interest-based ads on his or
                                her other devices. You must perform the opt-out on each browser or
                                device you use.
                            </p>
                            <p>
                                Furthermore, some of these opt-outs may not be effective unless your
                                browser is set to accept cookies. If you delete cookies, change your
                                browser settings, switch browsers or computers, or use another
                                operating system, you will need to opt-out again.
                            </p>
                        </div>
                        <hr />
                        <h5>LINKS TO THIRD PARTY SITES</h5>
                        <div className="para-set">
                            <p>
                                Our Websites may, from time to time, contain links to and from third
                                party websites. If you follow a link to any of these websites,
                                please note that these websites have their own privacy policies and
                                that we do not accept any responsibility or liability for their
                                policies. Please check the individual policies before you submit any
                                information to those websites.
                            </p>
                        </div>
                        <hr />
                        <h5>OUR POLICY TOWARDS CHILDREN</h5>
                        <div className="para-set">
                            <p>
                                The Services are intended to be used by adults and we do not
                                knowingly collect personal information from children under 13. If
                                you become aware that your child has provided us with personal
                                information, please contact us using the details below so that we
                                can take steps to remove such information and terminate any account
                                your child has created with us.
                            </p>
                        </div>
                        <hr />
                        <h5>CHANGES TO THIS POLICY</h5>
                        <div className="para-set">
                            <p>
                                We may update this Privacy Policy from time to time and so you
                                should review this page periodically. When we change this Privacy
                                Policy in a material way, we will update the "last modified" date at
                                the end of this Privacy Policy. Changes to this Privacy Policy are
                                effective when they are posted on this page.
                            </p>
                        </div>
                        <hr />
                        <h5>NOTICE TO YOU</h5>
                        <div className="para-set">
                            <p>
                                If we need to provide you with information about something, whether
                                for legal, marketing or other business related purposes, we will
                                select what we believe is the best way to get in contact with you.
                                We will usually do this through email or by placing a notice on our
                                Websites. The fact that we may send notices to you will not stop you
                                from being able to opt out of certain types of contact as described
                                in this Privacy Policy.
                            </p>
                        </div>
                        <hr />
                        <p>This Privacy Policy was last modified on 28st June 2022.</p>
                        ‍ <br />
                        <b>
                            ANNEX 1 – PERSONAL INFORMATION WE COLLECT WHEN YOU USE THE SERVICES
                            CATEGORIES OF PERSONAL INFORMATION
                        </b>
                        <br />
                        <br />
                        <h5>CONTACT INFORMATION AND BASIC PERSONAL DETAILS</h5>
                        <p>
                            Such as your full name, phone number, postal address, e-mail address,
                            zip code.
                        </p>
                        <p>
                            <b>How we use it</b>
                        </p>
                        <p>
                            <b>We use this information to:</b>
                        </p>
                        <div className="para-set">
                            <p>
                                Communicate with you, including sending statements and invoices,
                                communications, news, alerts and marketing communications;
                            </p>
                            <p>
                                Deal with enquiries and complaints made by or about you relating to
                                the Services; authentication purposes;
                            </p>
                            <p>
                                create customer accounts and operate, maintain and provide to you
                                the features and functionality of the Services.
                            </p>
                        </div>
                        <p>
                            <b>Legal basis for the processing</b>
                        </p>
                        <div className="para-set">
                            <p>
                                The processing is necessary for the performance of a contract and to
                                take steps prior to entering into a contract;
                            </p>
                            <p>
                                our legitimate interests, namely administering the Services, for
                                marketing purposes and communicating with users; and to comply with
                                certain legal obligations.
                            </p>
                        </div>
                        <hr />
                        <h5>‍WEBSITE, BUSINESS AND MARKETING INFORMATION</h5>
                        <p>
                            Such as company website, IP address, current email list size,
                            ecommerce software in use, email marketing software, product usage
                            information, demographic data, data about customer’s 3rd party
                            services, other business information (Facebook ad spend, status of
                            business, industry, business goals).
                        </p>
                        <p>
                            <b>How we use it</b>
                        </p>
                        <div className="para-set">
                            <p>
                                We use this information to operate, maintain and provide to you the
                                features and functionality of the Services; determine relevant
                                advertising / marketing activities.
                            </p>
                        </div>
                        <p>
                            <b>Legal basis for the processing</b>
                        </p>
                        <div className="para-set">
                            <p>
                                {" "}
                                The processing is necessary for the performance of a contract and to
                                take steps prior to entering into a contract; and our legitimate
                                interests, namely administering the Services and for marketing
                                purposes.
                            </p>
                        </div>
                        <hr />
                        <h5>PAYMENT INFORMATION</h5>
                        <p>
                            Details such as your credit card information, account information,
                            billing address and related information.
                        </p>
                        <p>
                            <b>How we use it</b>
                        </p>
                        <div className="para-set">
                            <p>
                                We use this information to facilitate payment for use of the
                                Services and to detect and prevent fraud.
                            </p>
                        </div>
                        <p>
                            <b>Legal basis for the processing</b>
                        </p>
                        <div className="para-set">
                            <p>
                                The processing is necessary for the performance of our contract with
                                you and our legitimate interests, namely the detection and
                                prevention of fraud.
                            </p>
                        </div>
                        <hr />
                        <h5>CORRESPONDENCE AND COMMENTS</h5>
                        <p>
                            When you contact us directly, e.g. by email, phone, mail, when you
                            post on message boards, blogs or complete an online form or provide
                            quality survey input, we will record your comments and opinions.
                        </p>
                        <p>
                            <b>How we use it</b>
                        </p>
                        <div className="para-set">
                            <p>
                                To address your questions, issues and concerns and improve the
                                Services, to determine products and services that may be of interest
                                to you and to send you news, alerts and marketing communications in
                                accordance with your marketing preferences.
                            </p>
                        </div>
                        <p>
                            <b>Legal basis for the processing</b>
                        </p>
                        <div className="para-set">
                            <p>
                                The processing is necessary for our legitimate interests, namely
                                communicating with users and for marketing purposes.
                            </p>
                        </div>
                        <hr />
                        <h5>ALL PERSONAL INFORMATION SET OUT ABOVE</h5>
                        <p>
                            We will use all the personal information we collect to prevent and
                            detect fraud, to operate, maintain and provide to you the features and
                            functionality of the Services, to communicate with you, to monitor and
                            improve the Services, our Website and business, and to help us develop
                            new products and services.
                        </p>
                        <p>
                            <b>How we use it</b>
                        </p>
                        <div className="para-set">
                            <p>
                                To address your questions, issues and concerns and improve the
                                Services, to determine products and services that may be of interest
                                to you and to send you news, alerts and marketing communications in
                                accordance with your marketing preferences.
                            </p>
                        </div>
                        <p>
                            <b>Legal basis for the processing</b>
                        </p>
                        <div className="para-set">
                            <p>
                                The processing is necessary for our legitimate interests, namely
                                communicating with users and for marketing purposes.
                            </p>
                        </div>
                        <hr />
                        <h5>CATEGORIES OF RECIPIENTS</h5>
                        <p>
                            As required in accordance with how we use it, we will share your
                            personal information with the following: ‍
                        </p>
                        <div className="sub-para">
                            <b>SERVICE PROVIDERS AND ADVISORS</b>
                            <p>
                                Third party vendors and other service providers that perform
                                services for us, on our behalf, which may include providing mailing
                                or email services, tax and accounting services, payments processing,
                                data enhancement services, fraud prevention, web hosting, or
                                providing analytic services. Your personal information is never
                                transferred to any third-party for the purposes of re-marketing to
                                you.
                            </p>
                            <b>
                                PURCHASERS AND THIRD PARTIES IN CONNECTION WITH A BUSINESS
                                TRANSACTION
                            </b>
                            <p>
                                Personal information may be disclosed to third parties in connection
                                with a transaction, such as a merger, sale of assets or shares,
                                reorganisation, financing, change of control or acquisition of all
                                or a portion of our business.
                            </p>
                            <b>LAW ENFORCEMENT, REGULATORS AND OTHER PARTIES FOR LEGAL REASONS</b>
                            <p>
                                Third parties as required by law or if we reasonably believe that
                                such action is necessary to (a) comply with the law and the
                                reasonable requests of law enforcement; (b) enforce our Terms of
                                Service or to protect the security or integrity of our Services or
                                Websites; and/or (c) exercise or protect the rights, property, or
                                personal safety of LoyaltyDish Technologies, our customers or others.
                            </p>
                            <b>‍ RETENTION PERIOD</b>
                            <p>
                                For no longer than necessary for the purposes set out and in
                                accordance with our legal obligations and legitimate business
                                interests.
                            </p>
                        </div>
                        <hr />
                        <b>
                            ANNEX 2 – PERSONAL INFORMATION COLLECTED AUTOMATICALLY CATEGORIES OF
                            PERSONAL INFORMATION
                        </b>
                        <br />
                        <br />
                        <h5>INFORMATION ABOUT HOW YOU ACCESS AND USE THE SERVICES</h5>
                        <p>
                            For example, the website from which you came and the website to which
                            you are going when you leave our Websites, how frequently you access
                            the Services, the time you access the Services and how long you use
                            them for, whether you open emails or click the links contained in
                            emails, whether you access the Services from multiple devices, and
                            other actions you take on the Services. ‍
                        </p>
                        <p>
                            <b>How we use it</b>
                        </p>
                        <div className="para-set">
                            <p>
                                Present the Services to you on your device; determine news, alerts
                                and other products and services that may be of interest to you for
                                marketing purposes; and monitor and improve the Services and our
                                business, and to help us develop new products and services.
                            </p>
                        </div>
                        <p>
                            <b>Legal basis for the processing</b>
                        </p>
                        <div className="para-set">
                            <p>
                                The processing is necessary for our legitimate interests, namely: to
                                tailor the Services to the user and to improve the Services
                                generally; to monitor and resolve issues; for marketing purposes; to
                                communicate with users; to contact users; and for the detection and
                                prevention of fraud. ‍
                            </p>
                        </div>
                        <hr />
                        <h5>INFORMATION ABOUT YOUR DEVICE</h5>
                        <p>
                            We also collect information about the computer, tablet, smartphone or
                            other electronic device you use to connect to the Services. This
                            information can include details about the type of device, unique
                            device identifying numbers, operating systems, browsers and
                            applications connected to the Services through the device, information
                            about the server upon which the Services operate, your Internet
                            service provider or mobile network, your IP address. ‍
                        </p>
                        <p>
                            <b>How we use it</b>
                        </p>
                        <div className="para-set">
                            <p>
                                We use this information to enable the Services to be presented to
                                you on your device; operate, maintain and provide to you the
                                features and functionality of the Services; monitor and improve the
                                Services and business, and to help us develop new products and
                                services.
                            </p>
                        </div>
                        <p>
                            <b>Legal basis for the processing</b>
                        </p>
                        <div className="para-set">
                            <p>
                                The processing is necessary for our legitimate interests, namely: to
                                tailor the Services to the user and to improve the Services
                                generally; to monitor and resolve issues; for marketing purposes; to
                                communicate with users; to contact users; and for the detection and
                                prevention of fraud.
                            </p>
                            <hr />
                            <h5>CATEGORIES OF RECIPIENTS</h5>
                            <p>
                                As required in accordance with how we use it, we will share your
                                personal information with the following:
                            </p>
                            <div className="sub-para">
                                <b>‍ SERVICE PROVIDERS AND ADVISORS</b>
                                <p>
                                    Third party vendors and other service providers that perform
                                    services for us, on our behalf, which may include providing
                                    mailing or email services, tax and accounting services, payments
                                    processing, data enhancement services, fraud prevention, web
                                    hosting, or providing analytic services. Your personal information
                                    is never transferred to any third-party for the purposes of
                                    re-marketing to you. ‍
                                </p>
                                <b>
                                    PURCHASERS AND THIRD PARTIES IN CONNECTION WITH A BUSINESS
                                    TRANSACTION
                                </b>
                                <p>
                                    Personal information may be disclosed to third parties in
                                    connection with a transaction, such as a merger, sale of assets or
                                    shares, reorganisation, financing, change of control or
                                    acquisition of all or a portion of our business.
                                </p>
                                <b>
                                    ‍ LAW ENFORCEMENT, REGULATORS AND OTHER PARTIES FOR LEGAL REASONS
                                </b>
                                <p>
                                    Third parties as required by law or if we reasonably believe that
                                    such action is necessary to (a) comply with the law and the
                                    reasonable requests of law enforcement; (b) enforce our Terms of
                                    Service or to protect the security or integrity of our Services or
                                    Websites; and/or (c) exercise or protect the rights, property, or
                                    personal safety of LoyaltyDish, our customers or others.
                                </p>
                                <b>‍ RETENTION PERIOD</b>
                                <p>
                                    For no longer than necessary for the purposes set out and in
                                    accordance with our legal obligations and legitimate business
                                    interests.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}