import React from "react";

export default function Pricing() {
    return (
        <>
            <div className="page-bg">
                <div className="page-header">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <h3>Start free, upgrade as you grow.</h3>
                            </div>
                            <div className="col-lg-6 col-md-6 col-sm-12">
                                <p>
                                    Enjoy all of our features on any plan. Send SMS worldwide. No
                                    credit card required when starting.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="customer-slider">
                        <div className="slider-text">
                            <span>Estimate your price</span>How many contacts do you have?
                        </div>
                        <input
                            type="range"
                            defaultValue={600}
                            min={100}
                            max={3000}
                            oninput="rangevalue.value=value"
                        />
                        <div className="value-output">
                            <output id="rangevalue">600</output>
                            <span>Contacts</span>
                        </div>
                    </div>
                </div>
                <div className="price-packages">
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-4 col-md-12">
                                <div className="price-box">
                                    <div className="price-header">
                                        <div className="price-title">
                                            Free{" "}
                                            <span>
                                                $0 <span>/Month</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="sep-line-top" />
                                    <div className="price-desc">
                                        <p>Best for Starting Businesses or for exploring Galncito</p>
                                    </div>
                                    <div className="price-action">
                                        <a href="" className="btn-line-blue block-btn">
                                            Start Free
                                        </a>
                                    </div>
                                    <div className="sep-line" />
                                    <div className="package-features">
                                        <ul>
                                            <li>
                                                <i className="fa fa-user" />
                                            </li>
                                            <li>
                                                Contacts
                                                <ul>
                                                    <li className="highlighted">Reach up to 1000 contacts</li>
                                                    <li>Unlimited contact list size</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <div className="sep-line" />
                                    </div>
                                    <div className="package-features">
                                        <ul>
                                            <li>
                                                <i className="fa fa-paper-plane" />
                                            </li>
                                            <li>
                                                Messages
                                                <ul className="checklist">
                                                    <li>Up to 3000 emails</li>
                                                    <li>Up to 50 SMS credit</li>
                                                    <li>Whatsapp/FB/Instagram*</li>
                                                    <li>Push Notification</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <div className="sep-line" />
                                    </div>
                                    <div className="package-features">
                                        <ul>
                                            <li>
                                                <i className="fa fa-chart-pie" />
                                            </li>
                                            <li>Customer 360 View</li>
                                        </ul>
                                        <div className="sep-line" />
                                    </div>
                                    <div className="package-features">
                                        <ul>
                                            <li>
                                                <i className="fa fa-chart-area" />
                                            </li>
                                            <li>
                                                Analytics
                                                <ul className="checklist">
                                                    <li>Sales &amp; Campaign Reporting</li>
                                                    <li>Social Media Dashboard</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <div className="sep-line" />
                                    </div>
                                    <div className="package-features">
                                        <ul>
                                            <li>
                                                <i className="fa fa-headset" />
                                            </li>
                                            <li>
                                                Support
                                                <ul className="checklist">
                                                    <li>Email Support</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12 ">
                                <div className="price-box">
                                    <div className="price-header blue-head">
                                        <div className="price-title">
                                            Standard{" "}
                                            <span>
                                                $15 <span>/Month</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="sep-line-top-blue" />
                                    <div className="price-desc">
                                        <p>
                                            Best for growing and medium-sized businesses focused on email
                                            marketing
                                        </p>
                                    </div>
                                    <div className="price-action">
                                        <a href="" className="btn-blue block-btn">
                                            Start Free
                                        </a>
                                    </div>
                                    <div className="sep-line" />
                                    <div className="package-features">
                                        <ul>
                                            <li>
                                                <i className="fa fa-user" />
                                            </li>
                                            <li>
                                                Contacts
                                                <ul>
                                                    <li className="highlighted">Reach up to 2500 contacts</li>
                                                    <li>Unlimited contact list size</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <div className="sep-line" />
                                    </div>
                                    <div className="package-features">
                                        <ul>
                                            <li>
                                                <i className="fa fa-paper-plane" />
                                            </li>
                                            <li>
                                                Messages
                                                <ul className="checklist">
                                                    <li className="highlighted">Up to 25000 emails</li>
                                                    <li className="highlighted">Up to 100 SMS credit</li>
                                                    <li>Whatsapp/FB/Instagram*</li>
                                                    <li>Push Notification</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <div className="sep-line" />
                                    </div>
                                    <div className="package-features">
                                        <ul>
                                            <li>
                                                <i className="fa fa-chart-pie" />
                                            </li>
                                            <li>Customer 360 View</li>
                                        </ul>
                                        <div className="sep-line" />
                                    </div>
                                    <div className="package-features">
                                        <ul>
                                            <li>
                                                <i className="fa fa-chart-area" />
                                            </li>
                                            <li>
                                                Analytics
                                                <ul className="checklist">
                                                    <li>Sales &amp; Campaign Reporting</li>
                                                    <li>Social Media Dashboard</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <div className="sep-line" />
                                    </div>
                                    <div className="package-features">
                                        <ul>
                                            <li>
                                                <i className="fa fa-headset" />
                                            </li>
                                            <li>
                                                Support
                                                <ul className="checklist">
                                                    <li className="highlighted">
                                                        Email Support with 2 day SLA
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                            <div className="col-lg-4 col-md-12">
                                <div className="price-box">
                                    <div className="price-header">
                                        <div className="price-title">
                                            Premium{" "}
                                            <span>
                                                $49 <span>/Month</span>
                                            </span>
                                        </div>
                                    </div>
                                    <div className="sep-line-top" />
                                    <div className="price-desc">
                                        <p>
                                            Best for high-volume senders aimed at adding extra power with
                                            SMS
                                        </p>
                                    </div>
                                    <div className="price-action">
                                        <a href="" className="btn-line-blue block-btn">
                                            Start Free
                                        </a>
                                    </div>
                                    <div className="sep-line" />
                                    <div className="package-features">
                                        <ul>
                                            <li>
                                                <i className="fa fa-user" />
                                            </li>
                                            <li>
                                                Contacts
                                                <ul>
                                                    <li className="highlighted">Reach up to 1000 contacts</li>
                                                    <li>Unlimited contact list size</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <div className="sep-line" />
                                    </div>
                                    <div className="package-features">
                                        <ul>
                                            <li>
                                                <i className="fa fa-paper-plane" />
                                            </li>
                                            <li>
                                                Messages
                                                <ul className="checklist">
                                                    <li className="highlighted"> Unlimited emails</li>
                                                    <li className="highlighted">
                                                        Up to Up to 2000 SMS credit
                                                    </li>
                                                    <li>Whatsapp/FB/Instagram*</li>
                                                    <li>Push Notification</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <div className="sep-line" />
                                    </div>
                                    <div className="package-features">
                                        <ul>
                                            <li>
                                                <i className="fa fa-chart-pie" />
                                            </li>
                                            <li>Customer 360 View</li>
                                        </ul>
                                        <div className="sep-line" />
                                    </div>
                                    <div className="package-features">
                                        <ul>
                                            <li>
                                                <i className="fa fa-chart-area" />
                                            </li>
                                            <li>
                                                Analytics
                                                <ul className="checklist">
                                                    <li>Sales &amp; Campaign Reporting</li>
                                                    <li>Social Media Dashboard</li>
                                                </ul>
                                            </li>
                                        </ul>
                                        <div className="sep-line" />
                                    </div>
                                    <div className="package-features">
                                        <ul>
                                            <li>
                                                <i className="fa fa-headset" />
                                            </li>
                                            <li>
                                                Support
                                                <ul className="checklist">
                                                    <li>Email Support</li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <div className="all-feature">
                        <div className="feat-header">
                            <h3>All Plans include below features</h3>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-lg-3 col-md-6  col-xs-12">
                            <div className="feature-box">
                                <div className="features-icon">
                                    <img src="images/007-workflow.png" />
                                </div>
                                <span className="single-item">Workflow Orchestration</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6  col-xs-12">
                            <div className="feature-box">
                                <div className="features-icon">
                                    <img src="images/005-target-audience.png" />
                                </div>
                                <span className="single-item">Segmentation</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6  col-xs-12">
                            <div className="feature-box">
                                <div className="features-icon">
                                    <img src="images/004-decision-making.png" />
                                </div>
                                <span>Customer Lifetime Value Prediction</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6  col-xs-12">
                            <div className="feature-box">
                                <div className="features-icon">
                                    <img src="images/008-report.png" />
                                </div>
                                <span className="single-item">Analytics Reporting</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6  col-xs-12">
                            <div className="feature-box">
                                <div className="features-icon">
                                    <img src="images/technology 1.png" />
                                </div>
                                <span className="single-item">20+ External Integration</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6  col-xs-12">
                            <div className="feature-box">
                                <div className="features-icon">
                                    <img src="images/009-trend.png" />
                                </div>
                                <span className="single-item">Campaign Reporting</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6  col-xs-12">
                            <div className="feature-box">
                                <div className="features-icon">
                                    <img src="images/003-360-degrees.png" />
                                </div>
                                <span className="single-item">Real-Time Customer 360</span>
                            </div>
                        </div>
                        <div className="col-lg-3 col-md-6  col-xs-12">
                            <div className="feature-box">
                                <div className="features-icon">
                                    <img src="images/010-recommend-1.png" />
                                </div>
                                <span>Personalized Recommendation</span>
                            </div>
                        </div>
                    </div>
                    <hr />
                    <div className="social-features">
                        <div className="row">
                            <div className="col-lg-3 col-md-6  col-xs-12">
                                <div className="social-block">
                                    Social Media Dashboard
                                    <p>
                                        Boost revenue potential and create an exceptional customer
                                        experience by combining Social Media Platforms
                                    </p>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6  col-xs-12">
                                <div className="feature-box">
                                    <div className="features-icon">
                                        <img src="images/transaction 1.png" />
                                    </div>
                                    <span className="single-item">ROAS Reporting</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6  col-xs-12">
                                <div className="feature-box">
                                    <div className="features-icon">
                                        <img src="images/001-google-analytics.png" />
                                    </div>
                                    <span>Google Analytics web Traffic</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6  col-xs-12">
                                <div className="feature-box">
                                    <div className="features-icon">
                                        <img src="images/002-share.png" />
                                    </div>
                                    <span>Top google based Social Media channels</span>
                                </div>
                            </div>
                            <div className="col-lg-3 hide-me"></div>
                            <div className="col-lg-3 col-md-6  col-xs-12">
                                <div className="feature-box">
                                    <div className="features-icon">
                                        <img src="images/004-facebook.png" />
                                    </div>
                                    <span className="single-item">Facebook</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6  col-xs-12">
                                <div className="feature-box">
                                    <div className="features-icon">
                                        <img src="images/003-instagram.png" />
                                    </div>
                                    <span className="single-item">Instagram</span>
                                </div>
                            </div>
                            <div className="col-lg-3 col-md-6  col-xs-12">
                                <div className="feature-box">
                                    <div className="features-icon">
                                        <img src="images/001-bing.png" />
                                    </div>
                                    <span className="single-item">Bing</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <section>
                <div className="faqs">
                    <div className="faqs-header">
                        <div className="container">
                            <div className="row">
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="tab-head">
                                        <div className="small-head">Frequently asked questions</div>
                                        <h3 className="cen-me">
                                            Any doubts? <br />
                                            We're here to help
                                        </h3>
                                        <div className="sep-l cen-me" />
                                    </div>
                                </div>
                                <div className="col-lg-6 col-md-6 col-sm-12">
                                    <div className="faq-act">
                                        <a href="" className="btn-line-blue">
                                            Start a Free Trial Now
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container">
                        <div className="row">
                            <div className="col-lg-12">
                                <div className="accordion" id="accordionExample">
                                    <div className="card custom-card">
                                        <div className="card-header" id="headingOne">
                                            <a
                                                href=""
                                                data-toggle="collapse"
                                                data-target="#collapseOne"
                                                aria-expanded="true"
                                                aria-controls="collapseOne"
                                            >
                                                It’s my first time trying email marketing. Can I use
                                                Galncito for free? <i className="fa fa-chevron-down" />
                                            </a>
                                        </div>
                                        <div
                                            id="collapseOne"
                                            className="collapse show"
                                            aria-labelledby="headingOne"
                                            data-parent="#accordionExample"
                                        >
                                            <div className="card-body">
                                                Anim pariatur cliche reprehenderit, enim eiusmod high life
                                                accusamus terry richardson ad squid. 3 wolf moon officia
                                                aute, non cupidatat skateboard dolor brunch. Food truck
                                                quinoa nesciunt laborum eiusmod. Brunch 3 wolf moon tempor,
                                                sunt aliqua put a bird on it squid single-origin coffee
                                                nulla assumenda shoreditch et. Nihil anim keffiyeh
                                                helvetica, craft beer labore wes anderson cred nesciunt
                                                sapiente ea proident. Ad vegan excepteur butcher vice lomo.
                                                Leggings occaecat craft beer farm-to-table, raw denim
                                                aesthetic synth nesciunt you probably haven't heard of them
                                                accusamus labore sustainable VHS.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card custom-card">
                                        <div className="card-header" id="headingTwo">
                                            <a
                                                href=""
                                                data-toggle="collapse"
                                                data-target="#collapseTwo"
                                                aria-expanded="false"
                                                aria-controls="collapseTwo"
                                            >
                                                Will the Free plan give me access to all the features of
                                                your paid plans? <i className="fa fa-chevron-down" />
                                            </a>
                                        </div>
                                        <div
                                            id="collapseTwo"
                                            className="collapse"
                                            aria-labelledby="headingTwo"
                                            data-parent="#accordionExample"
                                        >
                                            <div className="card-body">
                                                While on a Free plan, you can explore all the features of
                                                our paid plans, as well as a variety of channels, including
                                                SMS, push notifications, Google Customer Match, and Facebook
                                                Custom Audiences. The only limitation on a Free plan is that
                                                you can send a maximum of 500 emails/month to up to 250
                                                contacts.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card custom-card">
                                        <div className="card-header" id="headingThree">
                                            <a
                                                href=""
                                                data-toggle="collapse"
                                                data-target="#collapseThree"
                                                aria-expanded="false"
                                                aria-controls="collapseThree"
                                            >
                                                This will be my first time setting up an automation
                                                workflow. Can I expect any help?{" "}
                                                <i className="fa fa-chevron-down" />
                                            </a>
                                        </div>
                                        <div
                                            id="collapseThree"
                                            className="collapse"
                                            aria-labelledby="headingThree"
                                            data-parent="#accordionExample"
                                        >
                                            <div className="card-body">
                                                While on a Free plan, you can explore all the features of
                                                our paid plans, as well as a variety of channels, including
                                                SMS, push notifications, Google Customer Match, and Facebook
                                                Custom Audiences. The only limitation on a Free plan is that
                                                you can send a maximum of 500 emails/month to up to 250
                                                contacts.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card custom-card">
                                        <div className="card-header" id="headingfour">
                                            <a
                                                href=""
                                                data-toggle="collapse"
                                                data-target="#collapsefour"
                                                aria-expanded="false"
                                                aria-controls="collapsefour"
                                            >
                                                This will be my first time setting up an automation
                                                workflow. Can I expect any help?{" "}
                                                <i className="fa fa-chevron-down" />
                                            </a>
                                        </div>
                                        <div
                                            id="collapsefour"
                                            className="collapse"
                                            aria-labelledby="collapsefour"
                                            data-parent="#accordionExample"
                                        >
                                            <div className="card-body">
                                                While on a Free plan, you can explore all the features of
                                                our paid plans, as well as a variety of channels, including
                                                SMS, push notifications, Google Customer Match, and Facebook
                                                Custom Audiences. The only limitation on a Free plan is that
                                                you can send a maximum of 500 emails/month to up to 250
                                                contacts.
                                            </div>
                                        </div>
                                    </div>
                                    <div className="card custom-card">
                                        <div className="card-header" id="heading5">
                                            <a
                                                href=""
                                                data-toggle="collapse"
                                                data-target="#collapse5"
                                                aria-expanded="false"
                                                aria-controls="collapse5"
                                            >
                                                This will be my first time setting up an automation
                                                workflow. Can I expect any help?{" "}
                                                <i className="fa fa-chevron-down" />
                                            </a>
                                        </div>
                                        <div
                                            id="collapse5"
                                            className="collapse"
                                            aria-labelledby="collapse5"
                                            data-parent="#accordionExample"
                                        >
                                            <div className="card-body">
                                                While on a Free plan, you can explore all the features of
                                                our paid plans, as well as a variety of channels, including
                                                SMS, push notifications, Google Customer Match, and Facebook
                                                Custom Audiences. The only limitation on a Free plan is that
                                                you can send a maximum of 500 emails/month to up to 250
                                                contacts.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>

    )
}