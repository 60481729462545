import React, { useEffect } from "react";
import { Link } from "react-router-dom";


export default function Home() {

    return (
        <>
            <section class="main-intro">
                <div class="container-fluid">
                    <div class="row">
                        <div class="col-xl-4 col-lg-5 col-md-6 col-sm-12">
                            <div class="intro">

                                <div class="intro-spline">Restaurant POS Software made Simple!</div>
                                <div class="intro-title">Track and manage
                                    your business
                                    with Ease <span>Simplify and accelerate all aspect of running your restaurant, from pickup and delivery to front and back of the house</span></div>
                            </div>

                            <div class="email_catch">
                                <form>

                                    <div class="form-group cst-form">

                                        <input type="email" class="form-control custom-control" id="email" placeholder="Your email address" />
                                        <div class="btn-abs">
                                            <div class="btn-place">
                                                <button type="submit" class="btn btn-theme ">Free Demo</button>
                                            </div>

                                        </div>

                                    </div>


                                </form>
                            </div>

                            <div class="video-link">
                                See how it Works. <a href="" class="btn-link">Watch Video   <i class="fa-solid fa-play-circle"></i></a>
                            </div>

                        </div>

                        <div class="col-xl-8 col-lg-7 col-md-6 order-sm-12 ">
                            <div class="owl-carousel owl-theme intro-slides" >
                                <div class="item"><img src="images/slide-01.jpg" /> <span class="overlay-part"><img src="images/slid-part01.png" /></span></div>
                            <div class="item"><img src="images/slide-01.png" /></div>
                            <div class="item"><img src="images/slide-01.png" /></div>
                        </div>
                    </div>
                </div>
            </div>
        </section >

            <section class="mega-section" >
                <div class="sec-bg">
                    <div class="sec-bg2">
                        <div class="container-fluid">
                            <div class="row">
                                <div class="col-lg-12">

                                    <div class="sec-head">
                                        <h1>The all-in-one solution for your business</h1>
                                        <span>Comprehensive solution for managing all aspects of your business operations with user-friendly and efficient tools</span>
                                    </div>
                                </div>

                            </div>
                            <div class="row">

                                <div class="owl-carousel owl-theme solutions" >
                                    <div class="item">
                                        <div class="item-content">
                                            <div class="item-thumnail"><img src="images/f-01.jpg" /></div>
                                            <div class="item-des">
                                                Commerce Support <span>Our POS supports commerce by providing a user-friendly interface for order taking, payment processing, and inventory management.</span>
                                                <a href=""  >More Details</a>
                                            </div>
                                            <div class="item-links">
                                                <ul>
                                                    <li><a href="">Orders</a></li>
                                                    <li><a href=""> Inventory</a></li>
                                                    <li><a href=""> Payment </a></li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="item">
                                        <div class="item-content">
                                            <div class="item-thumnail"><img src="images/f-01.jpg" /></div>
                                            <div class="item-des">
                                                Customer Relationship Management <span>our software also offers robust customer relationship management features, including the ability to store customer information, track purchase history, and send targeted marketing campaigns. This helps you build stronger relationships with your customers and increase loyalty.
                                                </span>
                                                <a href=""  >More Details</a>
                                            </div>
                                            <div class="item-links">
                                                <ul>
                                                    <li><a href="">Customer </a></li>
                                                    <li><a href=""> Purchase History</a></li>
                                                    <li><a href="">Campaigns</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="item">
                                        <div class="item-content">
                                            <div class="item-thumnail"><img src="images/f-01.jpg" /></div>
                                            <div class="item-des">
                                                Banking Integration <span>Our POS Systemm allows you to process payments with various methods such as credit card and mobile payments, this feature simplifies the process of payment and reduces human errors</span>
                                                <a href=""  >More Details</a>
                                            </div>
                                            <div class="item-links">
                                                <ul>
                                                    <li><a href="">Process Payments </a></li>
                                                    <li><a href=""> Credit Card </a></li>
                                                    <li><a href=""> Mobile Payments</a></li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>

                                    <div class="item">
                                        <div class="item-content">
                                            <div class="item-thumnail"><img src="images/f-01.jpg" /></div>
                                            <div class="item-des">
                                                Employee Management  <span>Employee Management features, including scheduling, role management, and permission controls. This helps you to effectively manage your staff and ensure that they have the tools they need to provide excellent service to your customers.
                                                </span>
                                                <a href=""  >More Details</a>
                                            </div>
                                            <div class="item-links">
                                                <ul>
                                                    <li><a href="">Scheduling</a></li>
                                                    <li><a href=""> Role Management</a></li>
                                                    <li><a href=""> Permission </a></li>
                                                </ul>
                                            </div>
                                        </div>

                                    </div>


                                </div>
                            </div>

                        </div>
                    </div>




                </div>


            </section>


            <section class="sec-pads">
                <div class="container-fluid">
                    <div class="features-sec">
                        <div class="features-head">Features</div>
                        <div class="features-heading"><h1>Explore our Amazing Features </h1><span>Imagine having the power to manage your inventory, employees, and customer relationships all in one place. Our software makes it easy for you to track sales, generate reports, and make informed decisions about your business.</span></div>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="owl-carousel owl-theme features" >

                                <div class="item">
                                    <div class="fea-icon yellow-clr"><img src="images/order.png" /></div>
                                    <div class="sep-w"></div>
                                    <div class="fea-details">
                                        <div class="fea-head">
                                            Orders Tracking
                                            <span>take orders and send them directly to the kitchen for preparation, which helps to reduce wait times for customers and improve the overall dining experience</span>                </div>
                                    </div>
                                </div>

                                <div class="item">
                                    <div class="fea-icon green-clr "><img src="images/menu.png" /></div>
                                    <div class="sep-w"></div>
                                    <div class="fea-details">
                                        <div class="fea-head">
                                            Menu Management
                                            <span>take orders and send them directly to the kitchen for preparation, which helps to reduce wait times for customers and improve the overall dining experience</span>                </div>
                                    </div>
                                </div>



                                <div class="item">
                                    <div class="fea-icon mag-clr"><img src="images/credit.png" /></div>
                                    <div class="sep-w"></div>
                                    <div class="fea-details">
                                        <div class="fea-head">
                                            Payment Processing
                                            <span>take orders and send them directly to the kitchen for preparation, which helps to reduce wait times for customers and improve the overall dining experience</span>                </div>
                                    </div>
                                </div>

                                <div class="item">
                                    <div class="fea-icon lblue-clr"><img src="images/analytics.png" /></div>
                                    <div class="sep-w"></div>
                                    <div class="fea-details">
                                        <div class="fea-head">
                                            Reporting and Analytics
                                            <span>take orders and send them directly to the kitchen for preparation, which helps to reduce wait times for customers and improve the overall dining experience</span>                </div>
                                    </div>
                                </div>

                                <div class="item">
                                    <div class="fea-icon lblue-clr"><img src="images/analytics.png" /></div>
                                    <div class="sep-w"></div>
                                    <div class="fea-details">
                                        <div class="fea-head">
                                            Reporting and Analytics
                                            <span>take orders and send them directly to the kitchen for preparation, which helps to reduce wait times for customers and improve the overall dining experience</span>                </div>
                                    </div>
                                </div>

                                <div class="item">
                                    <div class="fea-icon lblue-clr"><img src="images/analytics.png" /></div>
                                    <div class="sep-w"></div>
                                    <div class="fea-details">
                                        <div class="fea-head">
                                            Reporting and Analytics
                                            <span>take orders and send them directly to the kitchen for preparation, which helps to reduce wait times for customers and improve the overall dining experience</span>                </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <scetion class="sec-pads">
                <div class="container-fluid">
                    <div class="sec-cen-head"><h1>Revolutionize the way you do business with our cutting-edge POS
                        technology designed for every business type and size</h1></div>

                    <div class="sec-details">
                        <div class="row">
                            <div class="col-lg-7 col-md-12">
                                <div class="sec-image"><img src="images/pos-use.jpg" /></div>
                            </div>

                            <div class="col-lg-5 col-md-12">
                                <div class="use-list">
                                    <ul>
                                        <li>
                                            <a href="">Food & Beverage <span class="use-icon clr1"><i class="fa fa-beer"></i></span> <span class="use-details">Tailored to meet the unique needs of restaurants, cafes, and bars</span></a>
                                        </li>
                                        <li>
                                            <a href="">Retail <span class="use-icon clr2"><i class="fa fa-shopping-bag"></i></span> <span class="use-details">allows retailers to track stock levels, set reorder points, and generate purchase orders and more...</span></a>
                                        </li>
                                        <li>
                                            <a href="">Beauty  <span class="use-icon clr3"><i class="fa fa-spa"></i></span> <span class="use-details">allows customers to book appointments online or in-store, and allows businesses to manage their appointments and staff schedules and more....</span></a>
                                        </li>
                                    </ul>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>



            </scetion>

            <section >
                <div class="container-fluid">
                    <div class="sec-head-side">
                        <div class="row">
                            <div class="col-lg-7 col-sm-12">
                                <h3>We believe that our software is the best choice for your business, it's designed to grow with your company</h3>
                            </div>
                            <div class="col-lg-5 col-sm-12">
                                Providing scalability for your future needs, you can also manage your business from anywhere, at any time, with our mobile-friendly interface
                            </div>
                        </div>
                    </div>


                </div>
                <div class="sec-res-bg">
                    <div class="container-fluid">
                        <div class="row">
                            <div class="col-lg-12">
                                <div class="res-mock"><img src="images/resp-mock.png" /></div>
                            </div>
                        </div>
                    </div>

                    <div class="container">
                        <div class="sec-act-btns">

                            <div class="row">
                                <div class="col-lg-7 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-8 col-md-8 col-sm-12"><div class="mock-lead">Upgrade to our advance POS today & take your business to the next level!</div></div>
                                        <div class="col-lg-4 col-md-4 col-sm-12"><div class="mock-link"><a href="" class="btn-theme2">Free Demo</a></div></div>
                                    </div>





                                </div>

                                <div class="col-lg-5 col-md-12">
                                    <div class="row">
                                        <div class="col-lg-6 col-md-6 col-sm-12"><div class="mock-lead">Pricing that works for you.</div></div>
                                        <div class="col-lg-6 col-md-6 col-sm-12"><div class="mock-link"><Link to="pricing" class="btn-theme3">Explore pricing</Link></div></div>
                                    </div>

                                </div>
                            </div>
                        </div>

                    </div>





                </div>





            </section>
        </>
    )
}