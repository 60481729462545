import React from "react";
import { Link } from "react-router-dom";


export default function Header() {
    return (
        <header id="bar">
            <div class="container-fluid">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="logo-place"><a href="/"><img src="images/logo.png" /><span>Loyalty<b>Dish</b></span></a>
                        </div>
                        <div class="menu-bar">
                            <div id="cssmenu">
                               <ul>
                                    <li><Link to="/" data-scroll-nav="2"> Features </Link></li>
                                    <li><Link to="pricing"> Pricing</Link></li>
                                    <li><Link to="contact"> Contact</Link></li>
                                </ul>
                            </div>

                        </div>
                        <div class="act-btns">
                            <ul>
                                <li><a href="app.loyaltydish.com" class="btn-line">Login</a></li>
                                <li><a href="" class="btn-theme hide-me">Free Demo</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
